













import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import axios from 'axios';

import { VueEditor } from 'vue2-editor';

import { CUSTOM_TOOLBAR, THIRTY_SECONDS_IN_MILLISECONDS } from '@/constant/Editor';

@Component({
  components: {
    VueEditor,
  },
})
export default class ExEditor extends Vue {
  @Prop() placeholder!: string;

  private CUSTOM_TOOLBAR = CUSTOM_TOOLBAR;

  private content = '';

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  @Watch('content')
  emitContent() {
    this.$emit('updated-content', this.content);
  }

  async handleImageAdded(file: any, Editor: any, cursorLocation: any, resetUploader: any) {
    const formData = new FormData();
    formData.append('file', file);

    axios({
      url: `${process.env.VUE_APP_API_STATIC_CDN}/photos`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    })
      .then((result) => {
        const img = new Image();

        img.src = result.data.data.url;

        img.onload = () => {
          Editor.insertEmbed(cursorLocation, 'image', result.data.data.url);

          resetUploader();

          this.$store.dispatch('Toast/setToast', {
            text: 'Imagem carregada com sucesso!',
            status: 'success',
          });
        };

        img.onerror = () => {
          console.error('Error loading image:', result.data.data.url);

          this.$store.dispatch('Toast/setToast', {
            text: 'Erro ao carregar a imagem',
            status: 'error',
            duration: THIRTY_SECONDS_IN_MILLISECONDS,
          });
        };
      })
      .catch((err) => {
        console.error(err);

        this.$store.dispatch('Toast/setToast', {
          text: 'Erro ao carregar a imagem',
          status: 'error',
          duration: THIRTY_SECONDS_IN_MILLISECONDS,
        });
      });
  }
}
