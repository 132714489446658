
















import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import ExEditor from '@/components/ExEditor/index.vue';

import { replaceText } from '@/share/Util/Text/index';

@Component({
  components: {
    ExEditor,
  },
})
export default class SubjectiveQuestionInput extends Vue {
  @Prop({ default: false }) isResult!: boolean;
  @Prop({ default: true }) showContent!: boolean;

  get contentSubjectiveQuestion() {
    if (!this.showContent) {
      return replaceText(this.$store.getters.contentSubjectiveQuestion);
    }

    return this.$store.getters.contentSubjectiveQuestion;
  }

  saveAnswerToStore(content: string) {
    this.$store.commit('setContentSubjectiveQuestion', content);
  }
}
